import { css } from 'styled-components';

export default css`
  position: relative;
  overflow: hidden;
  height: 100%;
  .header-footer {
    .app-header {
        display: none !important
    }

    .app-footer {
        display: none !important
    }
  }
  .logout {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
  }
  .password-requirements {
    font-family: 'Work Sans';
  }
`;
