import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Auth, Core, Form, Layout, Theme } from 'connex-cds';
import { ThemeProvider } from '@mui/material/styles';

import { LinkSection } from './LinkSection';
import { formConfig } from './form-config';
import LoginForm from './LoginForm';
import themes from './example-custom-component/themes';
import logo from '../../../assets/login-background.png';

const { useAuthContext } = Auth;
const { Column, Container } = Layout;
const { FormProvider } = Form;
const { Alert, SEVERITY } = Core;

const Login = () => {
  const [hasFormError, setHasFormError] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { login } = useAuthContext();
  const { themeName } = Theme.useThemeContext();

  const handleLogin = React.useCallback(
    async (...args) => {
      const response = await login(...args);

      if (response) {
        navigate('/', { replace: true, state: location.state });
      } else {
        setHasFormError(true);
      }
    },
    [location.state, login, navigate]
  );

  return (
    <Column className="content" style={{ height: '100%', backgroundImage: `url(${logo})`, backgroundSize: 'cover'}}>
      {location?.state?.message?.stringId ? (
        <Alert
          severity={SEVERITY.SUCCESS}
          title={location.state.message?.noTitle ? undefined : 'success'}
          message={location.state.message.stringId}
          closable
          showIcon
        />
      ) : null}
      <FormProvider onSubmit={handleLogin} config={formConfig}>
          <ThemeProvider theme={themes[themeName]}>
            <LoginForm hasFormError={hasFormError} />
          </ThemeProvider>
        <LinkSection />
      </FormProvider>
    </Column>
  );
};

export default Login;
