import React from 'react';

import Left from '../../../assets/Left.svg';
import Right from '../../../assets/Right.svg';


const Logo = () => {
  return (
    <>
      <div className="login-logo">
        <img src={Left} />&nbsp;
        <img src={Right} />
      </div>
    </>
  );
};
export default Logo;