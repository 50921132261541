import cn from 'classnames';
import { Core, getCachedItem, Layout, Localization, Theme, useEntityContext, useAppContext } from 'connex-cds';
import React from 'react';
import { useHref } from 'react-router';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { usePostMessageListener } from '../util/usePostMessageListener';
import { Profile } from '../views/core-apps/profile/Profile';
import { SimulateTrackit } from './SimulateTrackit';
import { useAppConfig } from './util';

const Styled = styled(Layout.Column)`
  z-index: 1;
  position: relative;
  overflow: auto;
  .overlay {
    pointer-events: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .spinner {
    &:not(.busy) {
      display: none;
    }
  }

  iframe {
    width: 100%;
    height: 100%;
    border: none;
    background-color: var(--primary-background-color);
    display: flex;
    flex-direction: column;
    &.trackIt {
      width: 979px;
      height: 488px;
    }
    &.busy {
      display: none;
    }
  }
`;

export const ServiceIframe = () => {
  const { themeName } = Theme.useThemeContext();
  const { userLocale } = Localization.useLocalizationContext();
  const { service } = useParams();
  const { entityRef } = useEntityContext();
  const location = useLocation();
  const href = useHref('');
  const [key, setKey] = React.useState(0);
  const config = useAppConfig({ service, location, href });
  const themeRef = React.useRef(themeName);
  const localeRef = React.useRef(userLocale);
  const [iframeLoading, setIframeLoading] = React.useState();
  const appContext = useAppContext();

  React.useEffect(() => {
    themeRef.current = themeName;
  }, [themeName]);

  const xConnexId = React.useMemo(() => {
    return getCachedItem('x-connex-id');
  }, []);

  const _src = React.useMemo(() => {
    if (!config) {
      return 'data:text/html, <div style={{backgroundColor: var(--primary-background-color}}></div>';
    }

    const ms = new Date().getTime();

    let srcUrl = config.hostname;
    return `${srcUrl}?theme=${themeRef.current}&language=${localeRef.current}&x-connex-id=${xConnexId}&cb=${ms}`;
  }, [config, xConnexId]);

  // can't use _src directly because we need to manually set it after ticket acceptance.
  const [src, setSrc] = React.useState(_src);

  React.useEffect(() => {
    appContext?.setIframeLoading?.(iframeLoading);
  }, [appContext, iframeLoading]);

  const iframeRef = React.useRef();

  React.useEffect(() => {
    const iframe = iframeRef.current;
    const fn = () => {
      setIframeLoading(false);
    };
    if (appContext?.appConfig?.type !== 'CX_APP_CORE') {
      setIframeLoading(true);
      iframe?.addEventListener?.('load', fn);
    }

    return () => {
      iframe?.removeListener?.('load', fn);
    };
  }, [_src, appContext?.appConfig?.type]);

  const [iframeLocation, setIframeLocation] = React.useState(null);

  React.useEffect(() => {
    setSrc(`${_src}`);
  }, [_src]);

  const simulateTrackIt = React.useMemo(() => {
    return appContext?.appRef === 'mt' && iframeLocation?.startsWith?.(`/${entityRef}/driver`);
  }, [appContext?.appRef, entityRef, iframeLocation]);

  const listener = React.useCallback(
    event => {
      const message = event?.data;

      if (simulateTrackIt && message?.type === 'submit') {
        setSrc(`${config?.hostname}/driver`);
        setKey(s => s + 1);
      }

      if (message?.locationChange) {
        setIframeLocation(message.locationChange);
      }
    },
    [config?.hostname, simulateTrackIt]
  );

  usePostMessageListener(listener);

  // TODO: Refactor to support internal (type = CX_APP_CORE) apps in a general way.

  return (
    <Styled className={cn('iframe-wrapper')}>
      <div className="overlay" />
      <Core.Spinner
        spin={true}
        className={cn({ busy: iframeLoading })}
        text={`Loading ${appContext?.appConfig?.name}`}
      />
      {appContext?.appConfig?.crn === 'settings' ? (
        <Profile />
      ) : (
        <SimulateTrackit simulate={simulateTrackIt}>
          <iframe
            className={cn({ busy: iframeLoading, trackIt: simulateTrackIt })}
            src={src}
            key={`${key}`}
            title="service-iframe"
            id="service-iframe"
            ref={iframeRef}
          />
        </SimulateTrackit>
      )}
    </Styled>
  );
};
