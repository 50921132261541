import Switch from '@mui/material/Switch';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

export default css``;

export const LoginSwitch = styled(Switch)`
  ${({ theme }) => css`
    width: 52px;
    height: 32px;
    padding: 0;

    .MuiSwitch-switchBase {
      padding: 0;
      margin: 4px;
      transition-duration: '300ms';

      &.Mui-checked {
        transform: translateX(22px);
        color: #fff;
        & + .MuiSwitch-track {
          background-color: #DE3B2F;
          opacity: 1;
          border: 0;
        };
        &.Mui-disabled + .MuiSwitch-track {
          opacity: 0.5;
        }
      }

      &.Mui-focusVisible .MuiSwitch-thumb {
        color: #33cf4d;
        border: 6px solid #fff;
      }

      &.Mui-disabled .MuiSwitch-thumb {
        color:
          ${theme.palette.grey[100]};
      }
      &.Mui-disabled + .MuiSwitch-track {
        opacity: ${theme.palette.mode === 'light' ? 0.7 : 0.3};
      }
    }

    .MuiSwitch-thumb {
      box-sizing: border-box;
      width: 24px;
      height: 24px;
    }
    .MuiSwitch-track {
      border-radius: 15px;
      background-color: #E9E9EA;
      opacity: 1;
      transition: ${theme.transitions.create(['background-color'], {
        duration: 500,
      })},
    },
  `}
`;

export const Styled = styled.div`
box-sizing: border-box;
/* center the main div, resize it? */
width: 40%;
min-width: 400px;
min-height: 500px;
max-width: 800px;
max-height: 700px;
height: 50%;
position: absolute;
top: 50%;
left: 50%;
margin-top: -15%;
margin-left: -20%;
color: white;

display: flex;
justify-content: center;

/* nice background */
background: linear-gradient(0deg, rgba(208, 188, 255, 0.05), rgba(208, 188, 255, 0.05)), rgba(28, 27, 31, 0.4);

border: 1px solid #939094;
box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
backdrop-filter: blur(40px);

/* Note: backdrop-filter has minimal browser support */

border-radius: 20px;

a {
  color: white;
}

.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 10px;
  .remember-me {
    display: flex;
    gap: 16px;
    align-items: center;
  }
}

span.title {
  /* Login with your credentials below */
  display: flex;
  justify-content: center;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 47px;
  padding-top: 2vh;
  padding-bottom: 2vh;

  /* Neutral/95 */
  color: #f4eff4;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}

span.form-error {
  color: #d43d2d;
  font-size: 16px;
  height: 40px;
  display: 'table' !important;
}

button.loginButton {
  border-radius: 12px !important;
  width: 100%;
  background-color: #ff4f02 !important;
  bottom: 10px
}

.center {
  padding: 30px 0 0 0;
  width: 90%;
  max-width: 600px;
  .CALogo {
    display: flex;
    justify-content: center;
  }
  .fields {
    display: flex;
    flex-direction: column;
    height: 100%
  }
}
`;
