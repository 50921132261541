import React from 'react';
import { Form, Localization } from 'connex-cds';
import IconButton from '@mui/material/IconButton';
import { get } from 'lodash';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import getPath from '../copied-from-sdk/getPath';
import { useIsFieldAllowed } from '../copied-from-sdk/useIsFieldAllowed';
import { coerceValue } from '../copied-from-sdk/util';

import { CustomInputAdornment, LoginTextField } from './style';

const LoginInput = React.forwardRef((props, ref) => {
  const formContext = Form.useFormContext();
  const isFieldAllowed = useIsFieldAllowed();
  const { isSubmitting, setFieldValue, disabled, handleBlur, values } = formContext;
  const { fieldConfig, isRequired, rows, multiline, validationText = true, ...otherProps } = props;

  const handleInputChange = React.useCallback(
    e => {
      const value = e.target.value;
      const transformedValue = fieldConfig?.transformOut?.(value);
      setFieldValue(fieldConfig.path, transformedValue || coerceValue({ type: fieldConfig.dataType, value }));
    },

    [fieldConfig, setFieldValue]
  );

  const handleClearInput = () => {
    setFieldValue(fieldConfig.path, '');
  };

  const value = React.useMemo(() => {
    const path = getPath(fieldConfig);
    const incomingValue = get(values, path);
    return fieldConfig?.transformIn ? fieldConfig?.transformIn?.(incomingValue) : incomingValue;
  }, [fieldConfig, values]);

  const translateMessage = Localization.useTranslateMessage();

  const label = React.useMemo(
    () => translateMessage(fieldConfig.labelStringId),
    [fieldConfig.labelStringId, translateMessage]
  );

  if (!isFieldAllowed(fieldConfig)) return null;

  return (
    <LoginTextField
      name={fieldConfig.path}
      label={label}
      data-testid={fieldConfig.testId}
      ref={ref}
      value={value}
      onBlur={handleBlur}
      disabled={disabled || isSubmitting}
      type={fieldConfig.password ? 'password' : 'text'}
      testId={fieldConfig.testId}
      {...otherProps}
      onChange={handleInputChange}
      InputProps={{
        endAdornment: (
          <CustomInputAdornment position="end">
            {value && (
              <IconButton aria-label="toggle" onClick={handleClearInput} onMouseDown={null} edge="end">
                <HighlightOffIcon />
              </IconButton>
            )}
          </CustomInputAdornment>
        ),
      }}
    />
  );
});

export default LoginInput;
