import { Form } from "connex-cds";
import React from "react";

export const useIsFieldAllowed = () => {
  const { mode } = Form.useFormContext();

  return React.useCallback(
    fieldConfig => {
      // no mode means we're not in an array.
      if (!mode) return true;

      // Check for allowedModes (string or array) in config.  If not specified, it's allowed.
      if (!fieldConfig?.allowedModes?.length) return true;

      // allowedModes can be a string or an array. Ensure we have an array.
      const allowedModes = [].concat(fieldConfig.allowedModes);

      return allowedModes.includes(mode);
    },
    [mode]
  );
};
