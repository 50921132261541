import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import styled from 'styled-components';

export const LoginTextField = styled(TextField)(() => ({
  width: '100%',

  '& label': {
    color: '#F4EFF4',
    '&.Mui-focused': {
      color: '#F4EFF4',
    },
  },

  '& .MuiInputBase-input': {
    color: '#F4EFF4',
    background: 'transparent !important',
  },

  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: '1px solid #F4EFF4',
    },
    '&:hover fieldset': {
      border: '1px solid #F4EFF4',
    },
    '&.Mui-focused fieldset': {
      border: '1px solid #F4EFF4',
    },
  },
}));

export const CustomInputAdornment = styled(InputAdornment)(() => ({
  display: 'flex',
  gap: '16px',

  '& .MuiIconButton-root': {
    color: '#F4EFF4',
  },
}));
