import { Layout } from 'connex-cds';
import React from 'react';
import cn from 'classnames';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

import Footer from '../unauthenticated/footer/Footer';
import Header from '../unauthenticated/header/Header';
import style from './style';

const Styled = styled.div`
  ${style}
`;
const { HeaderFooter } = Layout;

const PublicLayout = (props) => {
  return (
    <Styled className={cn('public-layout')}>
      <HeaderFooter>
        <Header />
        <Outlet />
        <Footer />
      </HeaderFooter>
    </Styled>
  );
};

export default PublicLayout;
