import cn from 'classnames';
import { Form, Localization } from 'connex-cds';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import CommandAlkonLogo from './command-alkon';
import { LoginSwitch, Styled } from './style';

const LoginForm = ({ hasFormError }) => {
  const { values, Components } = Form.useFormContext();
  const navigate = useNavigate();
  const { Username, Password, SubmitButton } = Components;

  const handleForgotClick = React.useCallback(() => {
    navigate('/forgotPassword', { state: { username: values?.credentials?.username } });
  }, [navigate, values?.credentials?.username]);

  return (
    <Styled className={cn('login')}>
      <div className="center">
        <div className="CALogo">
          <CommandAlkonLogo />
        </div>
        <span className="title">Login with your credentials below</span>
        {hasFormError && <span className="form-error">Invalid Username or Password</span>}
        <div className="fields">
          <div>
            <Username />
          </div>
          <div>
            <Password />
          </div>
          <div className="buttons">
            <span className="remember-me">
              <Localization.Translate stringId="rememberMe" />
              <LoginSwitch />
            </span>
            <a onClick={handleForgotClick} data-testid="forgot-password-link">
              <Localization.Translate stringId="forgotPassword" data-testid="forgot-password-text" />
            </a>
          </div>
          <SubmitButton className="loginButton" stringId="login" testId="login-button" />
        </div>
      </div>
    </Styled>
  );
};

export default LoginForm;
