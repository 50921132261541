import styled from '@emotion/styled';
import { Form, Localization } from 'connex-cds';
import { get } from 'lodash';
import React, { useState } from 'react';
import getPath from '../copied-from-sdk/getPath';
import { useIsFieldAllowed } from '../copied-from-sdk/useIsFieldAllowed';
import { coerceValue } from '../copied-from-sdk/util';
import { CustomInputAdornment, PasswordInputField } from './style';
import IconButton from '@mui/material/IconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const PasswordInput = React.forwardRef((props, ref) => {
  const formContext = Form.useFormContext();
  const isFieldAllowed = useIsFieldAllowed();
  const { isSubmitting, setFieldValue, disabled, handleBlur, values } = formContext;
  const { fieldConfig, isRequired, rows, multiline, validationText = true, ...otherProps } = props;

  const [hidden, setHidden] = useState(fieldConfig.password);

  const handleInputChange = React.useCallback(
    e => {
      const value = e.target.value;

      const transformedValue = fieldConfig?.transformOut?.(value);
      setFieldValue(fieldConfig.path, transformedValue || coerceValue({ type: fieldConfig.dataType, value }));
    },

    [fieldConfig, setFieldValue]
  );

  const handleEyeClick = () => {
    setHidden(!hidden)
  };

  const value = React.useMemo(() => {
    const path = getPath(fieldConfig);
    const incomingValue = get(values, path);
    return fieldConfig?.transformIn ? fieldConfig?.transformIn?.(incomingValue) : incomingValue;
  }, [fieldConfig, values]);

  const translateMessage = Localization.useTranslateMessage();

  const label = React.useMemo(
    () => translateMessage(fieldConfig.labelStringId),
    [fieldConfig.labelStringId, translateMessage]
  );

  const handleClearInput = () => {
    setFieldValue(fieldConfig.path, '');
  };


  if (!isFieldAllowed(fieldConfig)) return null;

  return (
    <PasswordInputField
      name={fieldConfig.path}
      label={label}
      data-testid={fieldConfig.testId}
      ref={ref}
      value={value}
      onBlur={handleBlur}
      disabled={disabled || isSubmitting}
      type={hidden ? 'password' : 'text'}
      testId={fieldConfig.testId}
      {...otherProps}
      onChange={handleInputChange}
      InputProps={{
        endAdornment: (
          <>
          <CustomInputAdornment position="end">
            <IconButton aria-label="toggle password visibility" onClick={handleEyeClick} edge="end">
              {hidden ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </CustomInputAdornment>
          <CustomInputAdornment position="end">
          {value && (
            <IconButton aria-label="toggle" onClick={handleClearInput} onMouseDown={null} edge="end">
              <HighlightOffIcon />
            </IconButton>
          )}
          </CustomInputAdornment>
          </>
        ),
      }}
    />
  );
});

export default PasswordInput;
