import { Form } from 'connex-cds';
import * as yup from 'yup';
import YupPassword from 'yup-password';
import LoginInput from './LoginInput';
import PasswordInput from './PasswordInput';
YupPassword(yup);

export const formConfig = {
  validationSchema: yup.object().shape({
    credentials: yup.object().shape({
      username: yup.string().required('required'),
      password: yup
        .string()
        .password()
        .min(8, 'passwordMinCharacters')
        .max(99, 'passwordMaxCharacters')
        .minLowercase(1, 'passwordLowerCase')
        .minUppercase(1, 'passwordUpperCase')
        .minNumbers(1, 'passwordNumber')
        .minSymbols(1, 'passwordSpecialCharacter')
        .required('required'),
    }),
  }),
  fields: [
    {
      path: 'credentials.username',
      name: 'username',
      labelStringId: 'username',
      dataType: Form.FIELD_TYPES.string,
      showRequiredIndicator: false,
      initialFocus: true,
      testId: 'login-username',
      Component: LoginInput,
    },
    {
      path: 'credentials.password',
      name: 'password',
      labelStringId: 'password',
      dataType: Form.FIELD_TYPES.string,
      showRequiredIndicator: false,
      password: true,
      testId: 'login-password',
      Component: PasswordInput,
    },
  ],
};
